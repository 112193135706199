import React from 'react';
import { Textfit } from 'react-textfit';

import "./HaushaltsfuehrungsSchaden.css";

window.addEventListener('load', () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    /* remove second/millisecond if needed - credit ref. https://stackoverflow.com/questions/24468518/html5-input-datetime-local-default-value-of-today-and-current-time#comment112871765_60884408 */
    now.setMilliseconds(null)
    now.setSeconds(null)
});

const Wrapper = ({ children }) => {
    return <div className="wrapper">{children}</div>;
};

const Screen = ({ value }) => {
    return (
        <Textfit className="screen" mode="single" max={70}>

            <label className="label" htmlFor="start-date">Von</label>
            <input className="input" type="date" id="start-date" name="start-date" defaultValue="2022-01-01" /><br></br>
            <label className="label" htmlFor="end-date">Bis</label>
            <input className="input" type="date" id="end-date" name="end-date" defaultValue="2022-01-01" /><br></br>

            <label className="label" htmlFor="mdh-value">MDH (%)</label>
            <input className="input" type="number" step="1" min="0" max="100" id="mdh-value" name="mdh-value" defaultValue="100" size="3" /><br></br>

            <label className="label" htmlFor="stundensatz-value">Stundensatz (&euro;)</label>
            <input className="input" type="number" step="0.1" id="stundensatz-value" name="stundensatz-value" defaultValue="7.5" size="4" /><br></br>

            <label className="label" htmlFor="wochenstunden-value">Wochenstunden (h)</label>
            <input className="input" type="number" step="0.1" id="wochenstunden-value" name="wochenstunden-value" defaultValue="14.0" size="4" /><br></br>

            <br></br><br></br><br></br>
            <label className="label" htmlFor="Haushaltsfuehrungsschaden">Haushaltsführungsschaden</label><br></br>
            <input className="input_hfs" type="text" id="Haushaltsfuehrungsschaden-value" readOnly={true} name="Haushaltsfuehrungsschaden-value" defaultValue="0" size="15" />
            <label>&euro;</label>

        </Textfit>
    );
};

const ButtonBox = ({ children }) => {
    return <div className="buttonBox">{children}</div>;
};

const Button = ({ className, value, onClick }) => {
    return (
        <button className={className} onClick={onClick}>
            {value}
        </button>
    );
};

function BerechneHaushaltsfuehrungsSchaden() {
    console.log(`Calc clicked!`);

    var startDate = document.getElementById('start-date').value
    var endDate = document.getElementById('end-date').value
    const tmp = (new Date(endDate) - new Date(startDate));
    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const weekDays = diffDays / 7;
    
    const haushaltsfuehrungsSchaden = (parseFloat(weekDays) * parseFloat(document.getElementById('mdh-value').value) * parseFloat(document.getElementById('stundensatz-value').value) * parseFloat(document.getElementById('wochenstunden-value').value)) / 100.0;
    document.getElementById('Haushaltsfuehrungsschaden-value').value = haushaltsfuehrungsSchaden.toFixed(2);
}

function HaushaltsfuehrungsSchaden() {
    return (
        <Wrapper>
            <Screen value="0" />
            <ButtonBox>
                {
                    <Button key="calc" class="" className="center" value="Berechnen" onClick={BerechneHaushaltsfuehrungsSchaden}></Button>
                }
            </ButtonBox>
        </Wrapper>
    );
}

export default HaushaltsfuehrungsSchaden;